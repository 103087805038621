<template>
  <v-card>
    <v-card-text>
      <v-row dense class="mb-0 pb-0">
        <v-col cols="12" class="ml-auto" md="auto">
          <v-btn block color="primary" dense @click="surveyAddFormOpen">
            {{ $t('contacts.form.addForm') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- table -->
    <v-data-table
      item-key="id"
      :loading="loadingTable"
      :headers="customHeaders"
      :items="customDataForm"
      class="text-no-wrap"
      :footer-props="{
        'items-per-page-text': $t('rowsPerPageLabel'),
        'items-per-page-options': [5, 10, 25, 50, 75, 100],
      }"
    >
      <template #[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dense icon @click="selectAction('tags', item)">
              <v-icon color="#F15858">
                {{ icons.mdiLabelVariant }}
              </v-icon>
            </v-btn>
            <v-btn dense icon @click="selectAction('delete', item)">
              <v-icon color="#F15858">
                {{ icons.mdiTrashCanOutline }}
              </v-icon>
            </v-btn>
            <v-btn dense icon @click="selectAction('edit', item)">
              <v-icon color="#F15858">
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>
            <v-btn dense icon @click="selectAction('detail', item)">
              <v-icon color="#F15858">
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </v-btn>
          </template>
        </v-menu>
      </template>

      <template #[`item.tags`]="{ item }">
        <v-tooltip v-for="(tag, idx) in item.tags" :key="idx" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon fab v-bind="attrs" :color="tag.color" v-on="on">
              {{ icons.mdiLabelVariant }}
            </v-icon>
          </template>
          <span>Tag Name : {{ tag.name }}</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <!-- dialog confirmation -->
    <confirm ref="confirm"></confirm>

    <!-- dialog Edit -->
    <dialogBatchEdit ref="dialogBatchEdit"></dialogBatchEdit>

    <!-- dialog Tag -->
    <dialogSetTagsForm ref="dialogSetTagsForm" @fetch-form="fetchForm"></dialogSetTagsForm>

    <!-- dialog Add Form -->
    <dialogAddForm
      ref="dialogAddForm"
      :selected="selected"
      :selected-tags="selectedTags"
      :tags-list="tagsList"
      :is-cancelled-form="isCancelledForm"
      @fetch-form="fetchForm"
      @open-tags="setTagsOpen"
      @clear-tagsList="clearTagsList"
      @clear-selectedTags="clearSelectedTags"
      @cancel-form="formIsCancelled"
    ></dialogAddForm>

    <dialogSetTags
      ref="dialogSetTags"
      :is-cancelled-form="isCancelledForm"
      @save-tags="saveSelectedTags"
      @fetch-form="fetchForm"
      @cancel-form="cancelForm"
    ></dialogSetTags>

    <dialogSelectAddResponse ref="dialogSelectAddResponse" :form-data="form"></dialogSelectAddResponse>
  </v-card>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import { mdiDotsVertical, mdiEyeOutline, mdiLabelVariant, mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import PouchDB from 'pouchdb-browser'

import { default as clientPocketBase } from '@/helpers/pocketbase'
import { emailValidator, required } from '@core/utils/validation'
import contactMixin from '../../mixins/contactMixin'
import firestoreDbMixin from '../../mixins/firestoreDbMixin'

// import custom
import pouchDbMixin from '../../mixins/pouchDbMixin'
import utilsMixin from '../../mixins/utilsMixin'
import confirm from '../component/Confirm.vue'
import dialogAddForm from '../component/DialogAddForm.vue'
import dialogBatchEdit from '../component/DialogBatchEdit.vue'
import dialogSelectAddResponse from '../component/DialogSelectAddResponse.vue'
import dialogSetTags from '../component/DialogSetTags.vue'
import dialogSetTagsForm from '../component/DialogSetTagsForm.vue'

// eslint-disable-next-line import/no-unresolved
PouchDB.adapter('worker', require('worker-pouch'))

function wrapCsvValue(val, formatFn) {
  let formatted = formatFn !== void 0 ? formatFn(val) : val

  formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

  formatted = formatted.split('"').join('""')

  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export default {
  setup() {
    const tab = ref('')
    const tabs = [{ title: 'Form' }, { title: 'Setting' }]

    return {
      validators: {
        required,
        emailValidator,
      },
      tab,
      tabs,
    }
  },
  name: 'SurveyCreator',
  components: {
    confirm,
    dialogBatchEdit,
    dialogSetTagsForm,
    dialogAddForm,
    dialogSetTags,
    dialogSelectAddResponse,
  },
  mixins: [firestoreDbMixin, pouchDbMixin, contactMixin, utilsMixin],
  data() {
    return {
      surveyOpen: false,
      dialogForm: false,
      actionItems: [
        { title: 'Set Labels', value: 'tags' },
        { title: 'Edit', value: 'edit' },
        { title: 'Add Response', value: 'response' },
        { title: 'Show Detail', value: 'detail' },
        { title: 'Delete', value: 'delete' },
      ],
      customHeaders: [
        {
          text: this.$t('contacts.form.headers.formName'),
          value: 'name',
        },
        {
          text: this.$t('contacts.form.headers.contentAmount'),
          value: 'amount',
        },
        {
          text: this.$t('contacts.form.headers.label'),
          value: 'tags',
        },
        {
          text: this.$t('contacts.form.headers.createdAt'),
          value: 'createdAt',
        },
        {
          text: this.$t('contacts.form.headers.lastUpdated'),
          value: 'updatedAt',
        },
        {
          text: this.$t('contacts.form.headers.actions'),
          value: 'action',
        },
      ],
      customDataForm: [],
      icons: {
        mdiLabelVariant,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiEyeOutline,
      },
      selectedAction: '',
      loadingTable: false,
      selected: [],
      selectedTags: [],
      tagsList: [],
      form: [],
      isCancelledForm: false,
    }
  },
  computed: {},
  watch: {},
  beforeDestroy() {},
  created() {},
  async mounted() {
    this.fetchForm()
  },
  methods: {
    cancelForm(cancel = true) {
      this.isCancelledForm = cancel
    },
    formIsCancelled(cancel = true) {
      this.isCancelledForm = cancel
    },
    surveyAddFormOpen() {
      this.$refs.dialogAddForm.open()
    },
    setTagsOpen(status, data) {
      // this.selected= []
      // this.selectedTags= []
      // this.tagsList = []
      if (data) {
        this.$refs.dialogSetTags.open(status, data)
      } else {
        this.$refs.dialogSetTags.open(status)
      }
    },
    async saveSelectedTags(rawData) {
      const tagsName = []
      this.tagsList = []
      const selected = Object.keys(rawData)
      for (let index = 0; index < selected.length; index++) {
        const tagId = selected[index]
        if (rawData[selected[index]] !== null) {
          const getOneTag = await clientPocketBase.collection('crm_tags').getOne(tagId)
          tagsName.push(getOneTag.name)
          this.tagsList.push({
            name: getOneTag.name,
            color: getOneTag.color,
            variant: rawData[selected[index]],
            id: tagId,
          })
        }
      }
      this.selected = rawData
      this.selectedTags = tagsName
    },
    clearTagsList() {
      this.tagsList = []
    },
    clearSelectedTags() {
      this.selectedTags = []
    },
    async selectAction(e, item) {
      this.selectedAction = e
      const self = this
      if (this.selectedAction === 'delete') {
        this.$refs.confirm
          .open(
            this.$t('delete'),
            `${this.$t('Delete Form')} ${item.name}
           ?`,
            { color: 'red' },
            this.$t('delete'),
          )
          .then(async agree => {
            if (agree) {
              this.loadingTable = true
              await clientPocketBase.collection('crm_contact_form').delete(item.id)
              this.fetchForm()
              this.loadingTable = false
            }
          })
          .catch(err => {
            console.log(err, 'err disconne')
          })
      } else if (this.selectedAction === 'edit') {
        const foundData = await clientPocketBase.collection('crm_contact_form').getOne(item.id)
        this.selected = foundData.raw_data
        this.tagsList = foundData.tags
        const tempSelectedTags = foundData.tags.map(el => {
          return el.name
        })
        this.selectedTags = tempSelectedTags
        this.$refs.dialogAddForm.open(item.id)
      } else if (this.selectedAction === 'tags') {
        this.$refs.dialogSetTags.open('tagForm', item.id)
      } else if (this.selectedAction === 'detail') {
        await this.$router.push({ name: `formDetail`, params: { formId: item.id } })
        location.reload()
      } else if (this.selectedAction === 'response') {
        const foundData = await clientPocketBase.collection('crm_contact_form').getOne(item.id)
        this.form = foundData
        this.$refs.dialogSelectAddResponse.open(this.form)
      }
      this.selectedAction = 0
    },
    dateFormater(date) {
      const d = new Date(this.$moment(this.$moment.utc(date).toDate()).local().format())
      const day = d.getDate()
      const month = d.toLocaleString('default', { month: 'long' })
      const year = d.getFullYear()
      let time

      if (d.toLocaleTimeString().length === 8) {
        time = d
          .toLocaleTimeString('id-ID', {
            hour12: false,
          })
          .slice(0, 6)
      } else {
        time = d
          .toLocaleTimeString('id-ID', {
            hour12: false,
          })
          .slice(0, 5)
      }

      return `${day} ${month} ${year} | ${time}`
    },
    async fetchForm() {
      this.loadingTable = true
      const formData = await clientPocketBase.collection('crm_contact_form').getFullList(5, {
        expand: `tags`,
        sort: `-updated`,
      })
      const tempAmmount = []

      for (let i = 0; i < formData.length; i++) {
        const el = formData[i]

        const responseData = await clientPocketBase.collection('crm_form_response_data').getFullList(200, {
          filter: `form = '${el.id}'`,
        })

        tempAmmount.push(responseData.length)
      }

      // console.log('sayang tempammount', tempAmmount)

      const formattedData = formData.map((el, idx) => {
        return {
          id: el.id,
          name: el.name,
          amount: `${tempAmmount[idx]} responden`,
          tags: el.tags,
          createdAt: this.dateFormater(el.created),
          updatedAt: this.dateFormater(el.updated),
        }
      })

      this.customDataForm = formattedData
      this.loadingTable = false
    },
  },
}
</script>
<style scoped>
#surveyCreator {
}
.svc-creator .svc-full-container .svc-creator__banner {
  display: none !important;
}
.heigth-dialog {
  max-height: 400px;
  overflow-y: auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
@media only screen and (max-width: 578px) {
  .sm-display {
    display: none;
  }

  .last-served-image {
    display: none;
  }

  .last-served {
    font-size: 12px;
  }

  .phone-number {
    font-size: 12px;
  }
}

@media only screen and (min-width: 578px) {
  .sm-display-mobile {
    display: none;
  }
}
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
.padding-hero {
  padding-left: 80px;
  padding-right: 80px;
}
.mobile-tag {
  display: none;
}
/* Mobile */

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .hide-lottie {
    display: none;
  }
  /* .hero-subtitle {
      width: 200px;
    } */
}
</style>
